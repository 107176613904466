import React, { FunctionComponent } from 'react';

// Libs
import classnames from 'classnames';
import { Modal, ModalBody } from 'reactstrap';

// Contexts
import { useViewDetected } from '@Components/Utils/ViewDetected';

// Styles
import styles from './ComingSoon.module.scss';

interface ComingSoonProps {
  isOpen: boolean;
  className?: string;
  onClose?: () => void;
}

const ComingSoon: FunctionComponent<ComingSoonProps> = ({ isOpen, onClose, className }) => {
  const { deviceType } = useViewDetected();

  const isMobile = deviceType !== 'desktop';

  const closeModal = () => {
    if (onClose) {
      onClose();
    }
  };

  const wrapperClassName = classnames([styles.wrapper, { [styles.mobile]: isMobile }, className]);

  return (
    <Modal centered toggle={closeModal} className={wrapperClassName} contentClassName={styles.content} isOpen={isOpen}>
      <ModalBody className={styles.modalBody}>
        <div className={styles.icon_close} onClick={closeModal} />

        <div className={styles.image} />

        <div className={styles.title}>Tính năng sẽ ra mắt trong thời gian tới !</div>

        <div className={styles.text}>Trân trọng cảm ơn</div>
      </ModalBody>
    </Modal>
  );
};

ComingSoon.defaultProps = {
  className: '',
};

export default ComingSoon;
